












import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general";

export default mixins(GeneralMixin).extend({
  name: "Snack",
  watch: {
    snackbar: function (value: any) {
      if (value && value.show) {
        setTimeout(() => {
          this.$store.dispatch("changeSnackbar", null);
        }, this.snackbar.timeout - 2000);
      }
    },
  },
});
