




























































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general";

export default mixins(GeneralMixin).extend({
  name: "ConfirmModal",
  data: () => ({
    invoice: "",
    trans_type: "",
    base: window.location.origin,
    remote: process.env.VUE_APP_API_BASE_URL,
  }),
  computed: {
    confirm_modal: {
      get(): any {
        return this.$store.getters["getConfirmModal"];
      },
      set() {
        this.closeModal();
      },
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch("changeConfirmModal", { show: false });
    },
    async pay(pay_type: string) {
      let data: any = await JSON.parse(JSON.stringify(this.confirm_modal));
      // this.closeModal();
      this.trans_type = data.quantity ? "card" : "topup";
      data.url = `order/${this.trans_type}`;
      data.payment_method = "1"
      if(pay_type == "perfectmoney"){
        data.payment_method = "2"
      } else if(pay_type == "mixpay") {
        data.payment_method = "6"
      }
      await this.$store.dispatch("postData", data).then(async (response) => {
        if (response.status) {
          this.invoice = await response.invoice;
          if (data.payment_method == "1" || data.payment_method == "6") {
            window.location.href = response.link;
          } else if (data.payment_method == "2") {
            let order_num = document.getElementById("order_num") as HTMLFormElement
            order_num.value = response.invoice;
            let pay_id = document.getElementById("pay_id") as HTMLFormElement
            pay_id.value = response.invoice;
            let cust_num = document.getElementById("cust_num") as HTMLFormElement
            cust_num.value = `${this.confirm_modal.email}response.invoice`;
            let pay = document.getElementById("pay") as HTMLFormElement
            pay.value = `${this.remote}/v2/pm_check?status=successful&tx_ref=${response.invoice}`;
            let no_pay = document.getElementById("no_pay") as HTMLFormElement
            no_pay.value = `${this.remote}/v2/pm_check?status=fail&tx_ref=${response.invoice}`;
            let pm = document.getElementById("pmform") as HTMLFormElement;
            pm.submit();
          }
        }
      });
    },
  },
});
