





















import Vue from "vue";
import Button from "./Button.vue";

export default Vue.extend({
  components: { Button },
  name: "Message",

  data: () => ({
    //
  }),
});
