






































































import mixins from "vue-typed-mixins";
import Button from "@/components/Button.vue";
import Chat from "@/components/Chat.vue";
import BuyCredit from "@/components/BuyCredit.vue";
// import AvailableCards from "@/components/AvailableCards.vue";
import Purchase from "@/components/Purchase.vue";
import Download from "@/components/Download.vue";
import Message from "@/components/Message.vue";
import Snack from "@/components/Snack.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import { GeneralMixin } from "@/mixins/general";

export default mixins(GeneralMixin).extend({
  name: "App",
  components: {
    Button,
    BuyCredit,
    // AvailableCards,
    Purchase,
    Download,
    Message,
    Snack,
    ConfirmModal,
    Chat,
  },
  data() {
    return {
      get_cards: 0,
      get_topups: 0,
    };
  },
  created() {
    this.getCards();
    this.get_cards = setInterval(this.getCards, 300000);
    this.getTopups();
    this.get_topups = setInterval(this.getCards, 300000);
    this.getRate();
    this.get_topups = setInterval(this.getRate, 300000);
  },
  beforeDestroy() {
    clearInterval(this.get_cards);
  },
  methods: {
    async getCards() {
      this.$store.dispatch("getData", { url: "card_list" });
    },
    async getTopups() {
      this.$store.dispatch("getData", { url: "topup_list/data" });
      this.$store.dispatch("getData", { url: "topup_list/bundle" });
    },
    async getRate() {
      this.$store.dispatch("getData", { url: "parameter" });
    },
    scrollTo(id: string) {
      document.getElementById(id)!.scrollIntoView({ behavior: "smooth" });
    },
  },
});
