import axios from "axios";
import config from "./config";

const baseUrl = config.apiUrl


const setCookie = (name: string) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    sessionStorage.setItem(name, cookieValue!)
}

const getCookie = (name: string) => {
    return sessionStorage.getItem(name) ? sessionStorage.getItem(name) : ''
}

const customAxios = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
});

const requestHandler = async (request: any) => {
    const csrftoken = getCookie("csrftoken");
    if (csrftoken) {
        request.headers["X-CSRFToken"] = csrftoken;
    }
    return request;
};

const responseHandler = (response: any) => {
    setCookie("csrftoken");
    return response;
};

const errorHandler = async (error: any) => {
    if (error.response && error.response.data && error.response.data.loc_error) {
        window.location.href = process.env.VUE_APP_API_BASE_URL;
    }
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default customAxios;
