
































































































































import mixins from "vue-typed-mixins";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Button from "@/components/Button.vue";
import { GeneralMixin } from "@/mixins/general";
import { Topup } from "@/store";

export default mixins(GeneralMixin).extend({
  name: "Purchase",

  data: () => ({
    settings: {
      dots: false,
      rows: 1,
      arrows: true,
      slidesPerRow: 1,
      speed: 500,
      centerPadding: "70px",
      focusOnSelect: true,
      infinite: true,
      slidesToScroll: 1,
    },
    data: false,
    item_id: null,
    topup: {} as Topup,
    denomination: 0,
    denomination_list: [],
    topup_list: [] as Topup[],
    email: "",
    phone: "",
    country_id: 0,
  }),
  components: { VueSlickCarousel, Button },
  methods: {
    changeType() {
      this.data = !this.data;
      this.country_id = 0;
      this.checkProducts();
    },
    checkProducts() {
      const items = this.data ? this.topups_data : this.topups_bundle;
      this.topup_list = items.filter(
        (val: any) => val.topup_country === this.country_id
      );
    },
    checkDenomination(val: any) {
      this.denomination = 0;
      const resp = this.getDenomination(
        val,
        this.data ? this.topups_data : this.topups_bundle, 
        "topup"
      );
      this.denomination_list = resp.denominations;
      this.topup = resp.item;
    },
    confirmOrder() {
      if (!this.item_id) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Select an airtime to buy.",
        });
      } else if (!this.denomination) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Select or enter amount.",
        });
      } else if (!this.email || !this.validate_email(this.email)) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Enter a valid email",
        });
      } else if (
        !this.phone ||
        this.phone.length > 15 ||
        this.phone.length < 10 ||
        this.phone.includes("+")
      ) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Enter valid phone number with country code exluding the +",
        });
      } else {
        let deno =  Number(this.denomination);
        let actual_deno = this.denomination;
        let usd = deno;
        if (this.denomination_list && this.denomination_list.length > 0) {
          const vv:any  = (this.denomination_list.filter((val: any) => val.amount === this.denomination)[0])
          actual_deno = vv.unit
          usd = actual_deno
        } else {
          deno = deno * this.rate.ngn_to_usd;
        }
        const amount_ngn = deno +  this.rate.topup * deno;
        const amount_pm = amount_ngn / this.rate.pm_to_ngn;
        const amount_usd = usd * this.rate.mixpay_usd;
        this.$store.dispatch("changeConfirmModal", {
          show: true,
          name: this.topup.name,
          id: this.topup.operator_id,
          email: this.email,
          phone: this.phone,
          show_denomination: actual_deno,
          denomination: this.denomination,
          amount_ngn: amount_ngn.toFixed(1),
          amount_pm: amount_pm.toFixed(1),
          amount_usd: amount_usd.toFixed(1),
        });
      }
    },
  },
});
