import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/home.vue";
import Index from "@/views/index.vue";
import State from "@/views/state.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Home,
    // redirect: "/participant/medidores",
    // meta: { requireAuth: true },
    children: [
      {
        path: "",
        name: "Index",
        component: Index,
      },

      //
    ],
  },
  {
    path: "/state",
    name: "State",
    component: State,
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  // },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   beforeEnter(to, from, next) {
  //     store.dispatch("logout").then(() => {
  //       next("/")
  //     })
  //   }
  // },
  // {
  //   path: "*",
  //   component: NotFound
  // }
];

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes,
});

// router.beforeEach((to, from, next) => {
// if (to.path == "/login" && store.getters.isAuthenticated) {
//   next({
//     name: "Home",
//     params: {
//       wantedRoute: to.fullPath
//     }
//   })
//   return
// }
// else if (to.matched.some(r => r.meta.requireAuth) && !store.getters.isAuthenticated) {
//   next({
//     name: "Login",
//     params: {
//       wantedRoute: to.fullPath
//     }
//   })
//   return
// }
// next()
// })

export default router;
