























import Vue from "vue";
import Navdrop from "./Navdrop.vue";

export default Vue.extend({
  components: { Navdrop },
  name: "Navbar",

  data: () => ({
    //
  }),
  methods: {
    scrollTo(id: string) {
      document.getElementById(id)!.scrollIntoView({ behavior: "smooth" ,block: 'start'});
    },
  },
});
