





































import Vue from "vue";

export default Vue.extend({
  name: "Navdrop",
  data: () => ({
    decision: "Exchange" as string,
    options: ["Buy Gift Card", "Buy Airtime", "Download App", "Contact Us"],
  }),
  watch: {
    // decision() {
    // },
  },
  methods: {
    scrollTo(id: string) {
      document
        .getElementById(id)!
        .scrollIntoView({ behavior: "smooth", block: "start" });
    },
  },
});
