



































import mixins from "vue-typed-mixins";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import InstantPurchase from "./InstantPurchase.vue";
import { GeneralMixin } from "@/mixins/general";

export default mixins(GeneralMixin).extend({
  name: "BuyCredit",
  components: { VueSlickCarousel, InstantPurchase },
  data: () => ({
    settings: {
      dots: false,
      rows: 1,
      slidesPerRow: 1,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            infinite: true,
            slidesToShow: 1,
            speed: 500,
            rows: 1,
            slidesPerRow: 1,
          },
        },
      ],
    },
    current: 1,
  }),
 

});
