



































































































import mixins from "vue-typed-mixins";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Button from "@/components/Button.vue";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { GeneralMixin } from "@/mixins/general";
import { Card } from "@/store";
export type Nullable<T> = T | null;

export default mixins(GeneralMixin).extend({
  name: "InstantPurchase",
  data: () => ({
    settings: {
      dots: false,
      rows: 1,
      slidesPerRow: 1,
      speed: 500,
      centerPadding: "70px",
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    item_id: null,
    card: {} as Card,
    denomination: 0,
    denomination_list: [],
    card_list: [] as Card[],
    email: "",
    phone: "",
    quantity: 1,
    country_id: 0,
  }),
  components: { VueSlickCarousel, Button },
  methods: {
    checkProducts() {
      this.card_list = this.cards.filter(
        (val: any) => val.card_country === this.country_id
      );
    },
    checkDenomination(val: any) {
      this.denomination = 0;
      const resp = this.getDenomination(val, this.cards, "card");
      this.denomination_list = resp.denominations;
      this.card = resp.item;
    },
    confirmOrder() {
      if (!this.item_id) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Select a card to buy.",
        });
      } else if (!this.denomination) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Select or enter a unit.",
        });
      } else if (!this.email || !this.validate_email(this.email)) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Enter a valid email",
        });
      } else if (!this.phone) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Enter phone number to receive giftcard",
        });
      } else if (!this.quantity || this.quantity < 1) {
        this.$store.dispatch("changeSnackbar", {
          show: true,
          message: "Quantity must be at least one",
        });
      } else {
        let deno = Number(this.denomination);
        let actual_deno = this.denomination;
        let usd = deno
        if (this.denomination_list && this.denomination_list.length > 0) {
          const vv: any = (this.denomination_list.filter((val: any) => val.amount === this.denomination)[0])
          actual_deno = vv.unit;
          usd = actual_deno
        } else {
          deno = deno * this.rate.ngn_to_usd;
        }
        const base_amount = this.quantity * deno;
        const amount_ngn =
          base_amount +
          Number(this.card.sender_fee) +
          this.rate.card * base_amount;
        const amount_pm = amount_ngn / this.rate.pm_to_ngn;
        const amount_usd = usd * this.rate.mixpay_usd * this.quantity;
        this.$store.dispatch("changeConfirmModal", {
          show: true,
          name: this.card.name,
          id: this.card.product_id,
          email: this.email,
          phone: this.phone,
          quantity: this.quantity,
          show_denomination: actual_deno,
          denomination: actual_deno,
          amount_ngn: amount_ngn.toFixed(1),
          amount_pm: amount_pm.toFixed(1),
          amount_usd: amount_usd.toFixed(2),
        });
      }
    },
  },
});
