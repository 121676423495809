import Vue from "vue";
import Vuex, { StoreOptions, GetterTree, MutationTree, ActionTree, Mutation } from "vuex";
import customAxios from "@/axios-service"
// import router from "@/router";

Vue.use(Vuex);

export type Nullable<T> = T | null;


export interface Card {
    id: number;
    card_country: number;
    product_id: number;
    name: string;
    image_url: Array<string>;
    sender_fee: number;
    denomination_map: Array<string>,
    card_key: string,
    pm_account: string
}
export interface Topup {
    id: number;
    topup_country: number;
    topup_id: number;
    operator_id: number;
    name: string;
    image_url: Array<string>;
    commission: number;
    denomination_map: Array<string>
}

export interface Snackbar {
    show: boolean | false;
    message: string | "";
    timeout: number | 7000;
    color: string | "#263238";
}

export interface ConfirmModal {
    show: boolean | false;
    name: any;
    id: any;
    email: any,
    show_denomination: any,
    denomination: any,
    quantity: any,
    phone: any,
    amount_ngn: any,
    amount_pm: any,
    amount_usd: any
}

export interface Country {
    id: number;
    iso_name: string;
    name: string;
}
export interface Rate {
    topup: number;
    card: number;
    pm: boolean;
    crypto: boolean;
    pm_to_ngn: number;
    ngn_to_usd: number;
    mixpay_usd: number;
    card_countries: Array<Country>
    topup_countries: Array<Country>
}

export interface Transaction {
    order_status: string;
    order_type: string;
    cards: [];
}
export interface RootState {
    cards: Array<Card>,
    topups_data: Array<Topup>,
    topups_bundle: Array<Topup>,
    rate: Rate,
    snackbar: Snackbar,
    confirm_modal: ConfirmModal,
    loading: boolean,
    transaction: Transaction
}

const getters: GetterTree<RootState, any> = {
    getConfirmModal: (state) => state.confirm_modal,
}

const mutations: MutationTree<RootState> = {
    updateState: (state, value: any) => {
        Object.assign(state, value)
    },
}

const actions: ActionTree<RootState, any> = {
    async getData({ commit }, data) {
        return customAxios
            .get(`/v2/${data.url}/`)
            .then((response) => {
                if (data.url == "card_list") {
                    return commit("updateState", { cards: response.data });
                } else if (data.url == "topup_list/data") {
                    return commit("updateState", { topups_data: response.data });
                } else if (data.url == "topup_list/bundle") {
                    return commit("updateState", { topups_bundle: response.data });
                } else if (data.url == "parameter") {
                    return commit("updateState", { rate: response.data });
                } else if (data.url == "parameter") {
                    return commit("updateState", { rate: response.data });
                } else if (data.url.includes("transaction")) {
                    return commit("updateState", { transaction: response.data });
                }
            })
            .catch((e) => {
                return false
            });
    },
    async postData({ commit }, data) {
        return customAxios
            .post(`/v2/${data.url}/`, data)
            .then((response) => {
                if (data.url.includes("order")) {
                    return response.data
                }
            })
            .catch((e) => {
                this.dispatch("changeSnackbar", { show: true, message: "An error occurred." })
                return { status: false }
            });
    },
    async changeSnackbar({ commit }, data) {
        return commit("updateState", {
            snackbar: {
                show: data && data.show ? data.show : false,
                message: data && data.message ? data.message : "",
                timeout: data && data.timeout ? data.timeout : 8000,
                color: data && data.color ? data.color : "#BF360C"
            }
        });
    },
    async changeConfirmModal({ commit }, data) {
        return commit("updateState", {
            confirm_modal: {
                show: data && data.show ? data.show : false,
                name: data && data.name ? data.name : null,
                quantity: data && data.quantity ? data.quantity : null,
                email: data && data.email ? data.email : null,
                show_denomination: data && data.show_denomination ? data.show_denomination : null,
                denomination: data && data.denomination ? data.denomination : null,
                phone: data && data.phone ? data.phone : null,
                id: data && data.id ? data.id : null,
                amount_pm: data && data.amount_pm ? data.amount_pm : null,
                amount_ngn: data && data.amount_ngn ? data.amount_ngn : null,
                amount_usd: data && data.amount_usd ? data.amount_usd : null
            }
        });
    },
    async changeLoading({ commit }, val) {
        return commit("updateState", {
            loading: val
        });
    },

}

const store: StoreOptions<RootState> = {
    strict: process.env.NODE_ENV !== 'production',
    modules: {},
    state: {
        cards: [] as Card[],
        topups_data: [] as Topup[],
        topups_bundle: [] as Topup[],
        rate: {} as Rate,
        snackbar: {} as Snackbar,
        confirm_modal: {} as ConfirmModal,
        loading: false,
        transaction: {} as Transaction
    },
    getters: getters,
    mutations: mutations,
    actions: actions
}

export default new Vuex.Store<RootState>(store);