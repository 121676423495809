import Vue from "vue";
import { mapState } from "vuex";

const GeneralMixin = Vue.extend({
    data: () => ({
    }),
    computed: {
        ...mapState(["cards", "topups_data", "topups_bundle", "snackbar", "rate", "loading", "transaction"]),
        form(): Vue & { validate: () => boolean } {
            return this.$refs.form as Vue & { validate: () => boolean };
        },
    },
    methods: {
        getDenomination(id: any, items: any, type = "card") {
            const denominations: any = []
            const item = items.filter((val: any) => val.id === id)[0]
            if (item.denomination_map) {
                let key = ""
                let value: any = null
                for ([key, value] of Object.entries(item.denomination_map)) {
                    if (type == "card") {
                        denominations.push({ amount: value, unit: key })
                    } else {
                        denominations.push({ unit: value, amount: key })
                    }
                }
            }
            return { denominations: denominations, item: item }
        },
        validate_email(email: string) {
            console.log(email)
            if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
                return true;
            } else {
                return false;
            }
        },
        implodeDenomination(deno: any) {
            if (deno) {
                return Object.keys(deno).map(function (key) { return key + " for " + deno[key] }).join(", ")
            } else {
                return "-"
            }
        }
    }
})

export { GeneralMixin }
export default {}
