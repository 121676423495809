
























































































import mixins from "vue-typed-mixins";
import { GeneralMixin } from "@/mixins/general";
export default mixins(GeneralMixin).extend({
  name: "State",
  data: () => ({
    status: "",
    invoice: "",
  }),
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const st = urlParams.get("status");
    this.status = st ? st : "";
    const invoice = urlParams.get("tx_ref");
    this.invoice = invoice ? invoice : "";
    if (this.status == "success" || this.status == "successful" || this.status =="completed" || this.status == "unknown") {
      this.getTransaction();
      console.log("yea")
    } else {
      setTimeout(() => {
        this.$router.push({ path: "/" });
      }, 10000);
    }
  },
  methods: {
    async getTransaction() {
      this.$store.dispatch("getData", { url: `transaction/${this.invoice}` });
    },
  },
});
