








import Vue from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "Home",
  data: () => ({}),
  components: { Navbar,Footer},
});
